<template>
  <Codes />
</template>

<script>
import Codes from "@/components/ticketsRegistration/Codes";

export default {
  name: "CodesPage",
  props: {},
  components: {
    Codes,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
